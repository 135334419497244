@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,600;0,700;1,400&display=swap");
body {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  background: url("./hero-bg.png") no-repeat;
  background-position: top center;
  background-size: cover;
  word-break: break-word;
}
.header-section {
  width: 61%;
  background-color: #000;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.logo-css {
  width: auto;
  height: 50px;
}
.header__cta {
  background: linear-gradient(
    90deg,
    rgba(91, 70, 123, 1) 57%,
    rgba(113, 72, 97, 1) 100%
  );
  border: 1px solid #fff;
  position: relative;
  height: 50px;
  border-radius: 12px;
  color: #fff;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.section-bg {
  width: 100%;
  background-image: url("./line.svg");
  background-repeat: no-repeat;
  background-position: center top;
  min-height: calc(-218px + 100vh);
  background-attachment: fixed;
}
.section-panel {
  width: 546px;
  padding: 20px;
  margin: 80px auto 0 auto;
  background: #000;
  border-radius: 20px;
  box-shadow: 1px 1px 20px 0px #504242;
}
.staking__info {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 10px;
  background: linear-gradient(
    to right,
    #c540ca 20%,
    #8126cb 40%,
    #fff 60%,
    #ffd507 80%,
    #ff6330 100%
  );
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
  text-align: center;
  margin: 0;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.counter {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.counter-panel {
  padding: 10px 20px;
  background: linear-gradient(to right, #3c1053, #ad5389);
  border-radius: 10px;
  margin-right: 20px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  width: 65px;
  height: 75px;
  position: relative;
}
.counter-panel-border::after {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.text-center {
  text-align: center;
}
.panel-bottom {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}
.counter-text {
  font-size: 32px;
}
.footer {
  background-color: #000;
  padding: 10px 0;
  position: fixed;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  max-width: 1078px;
  margin: 8% auto 0 auto;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}
.footer-title {
  color: #fff;
  font-weight: 800;
}
.footer-p {
  margin-top: 14px;
}
.footer-p p {
  color: #fff;
  opacity: 0.6;
  margin-bottom: 0px;
  font-size: 14px;
  margin-top: 5px;
}
.footer-p p a {
  color: #fff !important;
  opacity: 0.9;
  margin-bottom: 5px;
}
.disclamier {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  width: 620px;
  margin: 12px auto 0;
}
.social-flex {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 15px;
}
.menu-flex{
  display: flex;
  align-items: center;
  gap: 20px;
}
.menu-flex a{
  color: #fff !important;
  text-decoration: none;
  font-size: 20px;
  font-weight: 900;
}
.menu-gap{
  gap: 30px;
}
.menu-animation{
  background: linear-gradient(to left, #ab83ff 20%, #ffd507 40%, #ab08a6 60%, #ab08a6 80%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
@media (max-width: 991px) {
  .header-section {
    width: 85%;
    margin-top: 20px;
  }
  .section-panel {
    width: 85%;
    padding: 20px 10px;
  }
  .footer {
    max-width: 100%;
  }
  .disclamier {
    width: 100%;
  }
  .counter-panel {
    padding: 10px 12px;
    margin-right: 0;
  }
  .counter {
    gap: 5px;
  }
  .footer {
    width: 85%;
    padding: 10px;
}
body {
  background-color: #1B1D23;
}
}
@media (max-width: 767px) {
  .header-section {
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}
.header__cta {
  margin: 0 auto;
}
}
